<template>
  <div class="header">
    <div class="left-header">
      <div class="logo" @click="$router.push({ name: 'home' })">
        <img :src="logo" alt="logo" />
      </div>
      <div class="navigator">
        <RouterLink v-for="(item, index) in navigatorList" :to="{ name: item.to }" :key="index" class="navigator-item">
          <div class="divider" />
          <div class="navigator-item-content">
            <span class="en-label">{{ item.en }}</span
            ><span class="zh-label">{{ item.zh }}</span>
          </div>
        </RouterLink>
      </div>
    </div>
    <div class="right-header">
      <div class="links">
        <div class="wechat-container">
          <img :src="wechat" alt="wechat" />
          <img class="wechat-qrcode" :src="wechatQrcode" alt="wechat" />
        </div>
        <div class="divider" />
        <a target="_blank" href="https://github.com/datawhalechina">
          <img :src="github" alt="github" />
        </a>
      </div>
      <div v-if="authStore.isLogin">
        <el-popover popper-style="margin: 0; padding: 8px 0" trigger="hover">
          <div style="text-align: center">用户ID：{{ authStore.userId }}</div>
          <div
            style="text-align: center; margin: 0; cursor: pointer"
            @click="
              async () => {
                await logout();
              }
            "
          >
            退出登录
          </div>
          <template #reference>
            <img :src="authStore.avatarUrl" class="avatar" />
          </template>
        </el-popover>
      </div>
      <el-button v-else round @click="authStore.setAuth({ showLoginPanel: true })">
        <span style="color: rgb(35, 190, 255)">登录</span>
      </el-button>
    </div>
  </div>
  <RouterView />
  <div v-if="$route.name !== 'about'" class="footer">
    <div class="title">
      <span>datawhale.cn</span>
    </div>
    <div class="info">
      <div class="info-list">
        <span>关于我们</span>
        <span>
          <a href="https://www.datawhale.cn/about" target="_blank"> 关于Datawhale </a>
        </span>
        <span
          ><a
            href="https://mp.weixin.qq.com/mp/appmsgalbum?action=getalbum&__biz=MzIyNjM2MzQyNg==&scene=1&album_id=2624200781860225026&count=3#wechat_redirect"
            target="_blank"
            >贡献者们</a
          ></span
        >
        <span>
          <a href="https://jinshuju.net/f/OA3fi3" target="_blank">加入我们</a>
        </span>
      </div>
      <div class="info-list">
        <span>联系我们</span>
        <span>微信：hys_xiao</span>
        <span>邮箱：opensource@datawhale.club</span>
      </div>
      <div class="info-list">
        <span>社交媒体</span>
        <span>
          <a href="https://github.com/datawhalechina" target="_blank">GITHUB</a>
        </span>
        <span>
          <a href="https://space.bilibili.com/431850986" target="_blank"> B站 </a>
        </span>
        <span>
          <a href="https://blog.csdn.net/Datawhale" target="_blank">CSDN</a>
        </span>
      </div>
    </div>
  </div>
  <div class="administrative-footer">
    <div class="content">
      <span>
        DATAWHALE&emsp;|&emsp;和学习者一起成长&emsp;|&emsp;
        <a href="https://beian.mps.gov.cn/#/query/webSearch" target="_blank" style="color: white"
          >浙公安网备33011002017100号</a
        >
        &emsp;|&emsp;
        <a href="https://beian.miit.gov.cn" target="_blank" style="color: white">浙ICP备2024064617号-4</a>
      </span>
    </div>
  </div>
  <LoginWindow v-model="authStore.showLoginPanel" />
</template>

<script setup lang="ts">
import logo from '@/assets/imgs/logo.png';
import wechatQrcode from '@/assets/imgs/wechat-qrcode.png';
import github from '@/assets/svgs/github.svg';
import wechat from '@/assets/svgs/wechat.svg';
import { useAuthStore, USER_ROLE } from '@/stores';
import { getCookie, parseJwt } from '@/utils';
import { logout } from '@/utils/auth';
import { LoginWindow } from '@datawhale/login/index';

const authStore = useAuthStore();
// en表示英语，zh表示中文
const navigatorList = [
  {
    en: 'HOME',
    zh: '首页',
    to: 'home',
  },
  {
    en: 'ACTIVITY',
    zh: '活动',
    to: 'activity',
  },
  {
    en: 'LEARN',
    zh: '学习',
    to: 'learn',
  },
  {
    en: 'PRACTICE',
    zh: '实践',
    to: 'practice',
  },
  {
    en: 'AIGC',
    zh: 'AIGC',
    to: 'aigc',
  },
  {
    en: 'ABOUT US',
    zh: '关于我们',
    to: 'about',
  },
];

async function updateLoginStatus() {
  const jwt = getCookie('DATAWHALE_WEB_TOKEN');
  if (jwt) {
    const jwtPayload = parseJwt(jwt);
    if (Date.now() / 1000 > jwtPayload.exp) {
      await logout();
    } else {
      authStore.setAuth({
        isLogin: true,
        avatarUrl: jwtPayload.avatarUrl,
        nickName: jwtPayload.nickName,
        role: jwtPayload.isAdmin === 'false' ? USER_ROLE.USER : USER_ROLE.ADMIN,
        userId: Number(jwtPayload.sub),
        showLoginPanel: false,
      });
    }
  }
}

onMounted(() => {
  updateLoginStatus();
});
</script>

<style lang="scss" scoped>
@import '@/assets/fonts/index.css';

.divider {
  width: 2px;
  height: 12px;
  background-color: #898989;
}

.header {
  box-sizing: border-box;
  width: 100%;
  height: 60px;
  padding: 0 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .left-header {
    display: flex;
    flex-direction: row;
    align-items: center;

    .logo {
      width: 120px;
      height: 60px;
      display: flex;
      align-items: center;

      img {
        width: 100%;
        cursor: pointer;
      }
    }

    .navigator {
      display: flex;
      flex-direction: row;
      align-items: center;

      &-item {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 30px;
        user-select: none;
        cursor: pointer;
        text-decoration: none;
        transition: all 0.1s;
        color: black;

        &:visited {
          color: black;
        }

        &.router-link-active,
        &:hover {
          color: #23beff;
        }

        &-content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-left: 30px;

          span {
            transition: all 0.1s;

            &:nth-child(1) {
              font-size: 12px;
            }

            &:nth-child(2) {
              font-family: dingding;
              font-size: 20px;
            }
          }
        }
      }
    }
  }

  .right-header {
    display: flex;
    flex-direction: row;
    align-items: center;

    .links {
      display: flex;
      align-items: center;
      margin-right: 30px;

      img {
        width: 24px;
        height: 24px;
        margin: 10px;
        cursor: pointer;
      }

      .wechat-container {
        position: relative;

        .wechat-qrcode {
          display: none;
        }

        &:hover .wechat-qrcode {
          display: block;
          position: absolute;
          top: 42px;
          right: 24px;
          width: 180px;
          height: 180px;
          padding: 10px;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
          background: #fff;
          border-radius: 5px;
          z-index: 9998;
        }
      }
    }

    .el-button {
      border: 1px solid #d9f4ff;
      background-color: #e9f8ff;
      transition: all 0.3s;

      &:hover {
        background-color: #c3ecff;
      }
    }

    .avatar {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      cursor: pointer;
    }
  }
}

.footer {
  box-sizing: border-box;
  width: 100%;
  height: 150px;
  padding: 20px 53px;
  background: #1b1e32;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  a {
    color: white;
  }

  .title {
    font-size: 36px;
    font-family: aharoni;
    font-weight: bold;
    color: white;
  }

  .info {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    &-list {
      display: flex;
      flex-direction: column;
      margin-right: 160px;

      span {
        font-size: 12px;
        font-weight: normal;
        color: #e9e9e9;
        margin-top: 10px;
        cursor: pointer;

        &:nth-child(1) {
          font-size: 16px;
          font-weight: bold;
          color: #ffffff;
          margin-top: 20px;
          cursor: default;
        }
      }
    }
  }
}

.administrative-footer {
  width: 100%;
  height: 68px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1b1e32;

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    color: #ffffff;
  }
}
</style>
